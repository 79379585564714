import React from 'react'
import Container from "../../components/Container/Container";
import Content from '../../components/Content/Content';

export default function Impressum() {
  return (
    <>
      <section>
        <Content fullscreen="true">
          <Container size="large" margin="true">
            <h2>Impressum</h2>
            <h3>Gesetzliche Anbieterkennung:</h3>
            <p>
              IchWillWerben GmbH
              <br />
              Geschäftsführer:&nbsp;Carsten Jansen
              <br />
              Krampnitzer Weg 96
              <br />
              14089 Berlin
              <br />
              Deutschland
              <br />
              Kontakt:
              <br />
              Telefon: +49 (30) 629857-87
              <br />
              Fax: +49 (30) 629857-88
              <br />
              Email: info@ichwillwerben.de
            </p>
            <p>
              Umsatzsteueridentifikationsnummer (gemäß § 27 a Umsatzsteuergesetz): DE334671220
              <br />
              Steuernummer: 29/357/32267
              <br />
              UstID: DE334671220
              <br />
              Sitz der Gesellschaft: Berlin
              <br />
              Registergericht des Sitzes der Gesellschaft: Amtsgericht Charlottenburg
              <br />
              Handelsregister-Nr.: HRB 220331 B
            </p>
            <h3>Alternative Streitbeilegung:</h3>
            <p>
              Die Europäische Kommission stellt eine Plattform für die außergerichtliche
              Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar unter https://ec.europa.eu/odr.
            </p>
          </Container>
        </Content>
      </section>
    </>
  );
}
