import React from "react";
import VideoBackground from "../../components/VideoBackground/VideoBackground";
import Center from "../../components/Center/Center";
import styles from "./Home.module.scss";
import Icon from "../../components/Icon/Icon";
import Container from "../../components/Container/Container";
import FlexContainer from "../../components/Flex/FlexContainer";
import Flex from "../../components/Flex/Flex";
import { IconButton } from "@material-ui/core";
import Map from "../../components/Map/Map";
import Testimonial from "../../components/Testimonial/Testimonial";
import ButtonWhite from "../../components/Button/Button_white";
import { Link } from "react-router-dom";
import Image from "../../components/Image/Image";
import IconText from "../../components/Icon/IconText";

export default function Home() {
  return (
    <>
      <section>
        <VideoBackground
          width="1920"
          height="1080"
          source={
            <>
              <source src="/video/iww.webm" type="video/webm" />
              <source src="/video/iww.mp4" type="video/mp4" />
            </>
          }
        >
          <Center height="90vh" width="100%">
            <div className={styles.title}>
              <h3>Ichwillwerben</h3>
              <h1>
                <b>Effektive</b> und{" "}
                <b>
                  bezahlbare
                  <br />
                  Werbung
                </b>{" "}
                für <b>alle</b>!
              </h1>
              <IconButton className={styles.arrow}>
                <Icon name="fas fa-angle-down" size="48px" color="black" />
              </IconButton>
            </div>
          </Center>
        </VideoBackground>
      </section>

      <section
        style={{
          backgroundColor: "#282C34",
          backgroundImage: "url(/images/flow_bg_black_2.svg)",
          backgroundSize: "cover",
        }}
        className={styles.firstSection}
      >
        <Container size="large" margin="true">
          <FlexContainer gap="true">
            <Flex size="5/12">
              <img
                src="images/leinwand4.png"
                alt="panel"
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  transform: "translateY(-12%)",
                  margin: "auto",
                }}
              />
            </Flex>
            <Flex size="7/12">
              <Center>
                <div style={{ marginBottom: "10%" }}>
                  <h2>
                    Ihre Werbung, <b>heller</b> und <br />
                    <b>größer</b> als je zuvor!
                  </h2>
                  <p style={{ marginBottom: "32px" }}>
                    Mit unserem brandneuen LED-Wand haben Sie die Möglichkeit, Ihre Botschaft auf
                    eine völlig neue Art und Weise zu präsentieren. Erreichen Sie Ihr Publikum mit
                    leuchtender und auffälliger Werbung, die garantiert ins Auge fällt. Unsere
                    hochmodernen LED-Panels sorgen für maximale Sichtbarkeit und heben Ihre Marke
                    hervor wie nie zuvor. Ob Tag oder Nacht, Ihre Werbung wird immer strahlen und
                    die Aufmerksamkeit auf sich ziehen. Nutzen Sie diese einzigartige Chance, um
                    Ihre Reichweite zu steigern und mehr Kunden zu gewinnen. Setzen Sie Ihre Marke
                    ins richtige Licht und entdecken Sie die Vorteile unserer innovativen
                    LED-Werbung. Ihr Erfolgsweg beginnt hier - auf unserem neuen LED-Wand!
                  </p>
                  <a href="/downloads/vertrag_led.pdf" download>
                    <ButtonWhite startIcon={<Icon name="fas fa-download" />} size="large">
                      Jetzt Vertrag downloaden
                    </ButtonWhite>
                  </a>
                </div>
              </Center>
            </Flex>
          </FlexContainer>
        </Container>
      </section>

      <section>
        <Container size="small" margin="true">
          <FlexContainer>
            <Flex size="1/3">
              <img
                src="images/vertrag.png"
                alt="vertrag"
                style={{ height: "auto", maxWidth: "100%", margin: "auto" }}
              />
            </Flex>
            <Flex size="8/12">
              <Center>
                <div className={styles.text_margin}>
                  <h2>
                    <b>Vertrag</b> jetzt <b>downloaden</b>!
                  </h2>
                  <p>
                    In unserem Vertrag finden Sie alle Informationen, die Sie benötigen, um sich
                    einen, der begehrten Werbeslots auf unserer LED-Wand zu sichern. Jetzt
                    downloaden!
                  </p>
                  <a href="/downloads/vertrag_led.pdf" download>
                    <IconButton>
                      <Icon name="fas fa-download" />
                    </IconButton>
                  </a>
                </div>
              </Center>
            </Flex>
          </FlexContainer>
        </Container>
      </section>

      <section style={{ backgroundColor: "#282C34" }}>
        <FlexContainer>
          <Flex size="5/12">
            <Center>
              <div className={styles.map_text}>
                <h2>
                  An diesen <b>Standorten</b> sind
                  <br />
                  noch <b>Werbeslots</b> verfügbar
                </h2>
                <p>
                  Sie wollen einfach nur Werbung für ihr Unternehmen schalten, haben aber keinen
                  Standort, an dem Sie werben können? Kein Problem, mieten Sie jetzt ganz einfach
                  Werbezeit bei unseren Partnern. Wählen Sie ganz einfach auf der Karte einen
                  verfügbaren Standpunkt aus und schalten Sie Werbung.
                </p>
                <Link to="/karte">
                  <ButtonWhite startIcon={<Icon name="fas fa-map-marker-alt" />} size="large">
                    Zur Karte
                  </ButtonWhite>
                </Link>
              </div>
            </Center>
          </Flex>
          <Flex size="7/12">
            <Map />
          </Flex>
        </FlexContainer>
      </section>

      <section style={{ backgroundImage: "url(/images/FlowBG_02.svg)", backgroundSize: "cover" }}>
        <Container size="large" margin="true">
          <div className={styles.testimonials}>
            <FlexContainer gap="true">
              <Flex size="1/3">
                <Testimonial
                  author={<a href="https://www.indat.de/">"Reinhard Höhne, Indat GmbH"</a>}
                  avatar={"/images/reinhard.png"}
                >
                  Super leicht zu bedienen, gutes Tool zur Umsatzsteigerung
                </Testimonial>
              </Flex>
              <Flex size="1/3">
                <Testimonial
                  author={<a href="https://www.mkgfachwerk.de/">Gregor Jeske, Mkgfachwerk</a>}
                  avatar={"/images/gregor.png"}
                >
                  Für meine Praxis genau das Richtige, tolles Infocenter.
                </Testimonial>
              </Flex>
            </FlexContainer>
          </div>
        </Container>
      </section>

      <section className={styles.broschuere}>
        <Container size="small" margin="true">
          <FlexContainer>
            <Flex size="1/3">
              <img
                src="images/flyer2.png"
                alt="Flyer"
                style={{ height: "auto", maxWidth: "100%", margin: "auto" }}
              />
            </Flex>
            <Flex size="8/12">
              <Center>
                <div className={styles.text_margin}>
                  <h2>
                    <b>Info-Flyer</b> jetzt <b>downloaden</b>!
                  </h2>
                  <p>
                    In unserem Flyer erfahren Sie kurz und knapp alles zu unserem Konzept, wer wir
                    sind und was Ihre Vorteile sind. Jetzt Flyer anfordern und downloaden.
                  </p>
                  <a href="/downloads/IchWillWerben_Flyer_BFC_Preussen.pdf" download>
                    <IconButton>
                      <Icon name="fas fa-download" />
                    </IconButton>
                  </a>
                </div>
              </Center>
            </Flex>
          </FlexContainer>
        </Container>
      </section>

      <section style={{ backgroundColor: "#282C34", height: "auto" }}>
        <FlexContainer>
          <Flex size="5/12">
            <Image src={"/images/panel_sdv_2.png"} height={"700px"} width={"100%"} />
          </Flex>
          <Flex size="7/12">
            <Center>
              <div className={styles.concept_text}>
                <h2>
                  So<b> funktioniert</b> unser <b>Konzept</b>
                </h2>
                <IconText
                  icon={
                    <Icon
                      name={"fas fa-tv"}
                      color={"var(--secondary)"}
                      size={"38px"}
                      frame={"48px"}
                    />
                  }
                >
                  <p>
                    Wir stellen Werbedisplays, bei unseren Partnern, zu einem sehr attraktiven Preis
                    auf. Im Gegenzug können Sie auf Ihrem Panel, und auf einem Weiteren, Ihre
                    Werbung präsentieren.
                  </p>
                </IconText>
                <IconText
                  icon={
                    <Icon
                      name={"fas fa-ad"}
                      color={"var(--secondary)"}
                      size={"48px"}
                      frame={"48px"}
                    />
                  }
                >
                  <p>
                    Leute, die Werbung schalten wollen, können auf diesen Panels Werbezeit buchen
                    und online bespielen.
                  </p>
                </IconText>
                <IconText
                  icon={
                    <Icon
                      name={"fas fa-mouse-pointer"}
                      color={"var(--secondary)"}
                      size={"48px"}
                      frame={"48px"}
                    />
                  }
                >
                  <p>
                    Nutzen Sie bequem unser Content Managemant System (CMS) um von überall aus
                    Änderungen vorzunehmen. Ganz einfach per Drag and Drop.
                  </p>
                </IconText>
                <IconText
                  icon={
                    <Icon
                      name={"fas fa-chart-line"}
                      color={"var(--secondary)"}
                      size={"48px"}
                      frame={"48px"}
                    />
                  }
                >
                  <p>
                    Skalieren Sie einfach vorhandene Kampagnen auf weitere Panels. Ob auf einem
                    Werbepanel oder in der ganzen Stadt, Sie entscheiden wo und wie Oft ihre Werbung
                    gezeigt wird.
                  </p>
                </IconText>

                <div className={styles.buttonBox}>
                  <Link to="/kontakt">
                    <ButtonWhite startIcon={<Icon name="fas fa-handshake" />} size="large">
                      Partner werden
                    </ButtonWhite>
                  </Link>
                  <Link to="/karte">
                    <ButtonWhite startIcon={<Icon name="fas fa-map-marker-alt" />} size="large">
                      Werbung buchen
                    </ButtonWhite>
                  </Link>
                </div>
              </div>
            </Center>
          </Flex>
        </FlexContainer>
      </section>
    </>
  );
}
