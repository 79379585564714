import React from 'react'
import { List, Card, Checkbox, ListItem, ListItemIcon, ListItemSecondaryAction, NativeSelect, FormControl, InputLabel, } from '@material-ui/core';
import styles from './MapList.module.scss'
import MapListSlot from './MapListSlot';
import Button from '../Button/Button'

export default function MapList({ panels, checked, checkedPanels, handleToggle, handleToggleAll, handleCheckout }) {
  const canCheckout = checked.length > 0;
  const price = checkedPanels.reduce((acc, panel) => acc + panel.price, 0);

  return (
    <div className={styles.map_list}>
      <Card className={styles.search}>
        <ListItem>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Age</InputLabel>
            <NativeSelect
              native={1}
              value={10}
              //onChange={handleChange}
              name="age"
              //className={classes.selectEmpty}
              inputProps={{ "aria-label": "age" }}
            >
              <option value="">None</option>
              <option value={10}>Ten</option>
              <option value={20}>Twenty</option>
              <option value={30}>Thirty</option>
            </NativeSelect>
          </FormControl>
        </ListItem>
      </Card>
      <Card className={styles.panel_container}>
        <ListItem button className={styles.top_menu} onClick={handleToggleAll()}>
          <ListItemIcon>
            <Checkbox checked={checked.length > 0} tabIndex={-1} disableRipple={true} />
          </ListItemIcon>
          {checked.length} Panels ausgewählt
        </ListItem>

        <List className={styles.list}>
          {panels.map((panel) => (
            <MapListSlot
              panel={panel}
              checked={checked}
              handleToggle={handleToggle}
              key={panel.name}
            />
          ))}
        </List>
      </Card>

      <Card className={styles.price}>
        <div className={styles.submin_container}>
          <p>
            <b>{price} €</b> pro Monat zzgl. Mwst.
          </p>
          <Button onClick={handleCheckout()} disabled={!canCheckout}>
            Jetzt Buchen
          </Button>
        </div>
      </Card>
    </div>
  );
}
