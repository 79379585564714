import { Avatar, Card, CardContent } from '@material-ui/core'
import React from 'react'
import Text from '../Text/Text'
import Center from '../Center/Center'

export default function Testimonial(props) {
    return (
        <Card variant="outlined">
            <CardContent>
                <Center>
                    <Avatar src={props.avatar} style={{ height: "128px", width: "128px" }}>
                    </Avatar>
                </Center>
                <Text type="quote" author={props.author}>{props.children}</Text>
            </CardContent>
        </Card>
    )
}
