import React from 'react'
import { Button as MaterialButton, withStyles, } from '@material-ui/core';

const ButtonStylized = withStyles({
    root: {
        boxShadow: 'none',
        backgroundColor: '#F36F6F',
        backgroundImage: 'linear-gradient(90deg, #F36F6F, #F0357D)',
        textTransform: 'none',
        padding: '0.3em 1.6em',
        color: 'white',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#F36F6F',
            filter: 'brightness(125%)',
        },
        '&:disabled': {
            color: 'rgba(255, 255, 255, 0.7)',
            backgroundColor: '#B0B0B0',
            backgroundImage: "none",
        },
    },
})(MaterialButton);


export default function Button(props) {
    return (
        <ButtonStylized {...props}/>
    )
}