import { Card, } from '@material-ui/core'
import React from 'react'
import Container from '../../components/Container/Container'
import Center from '../../components/Center/Center'
import { Link } from 'react-router-dom'
import Icon from '../../components/Icon/Icon'

export default function EmailReceived(props) {
	return (
		<>
			<section style={{ backgroundImage: "url(/images/FlowBG_03.svg)", backgroundSize: "cover" }} >
				<Center height="100vh">
					<Container size='large' margin="true">
						<Card variant="outlined" style={{ padding: "calc(var(--margin-large))", maxWidth: '780px' }}>
							<h3>Angebot wird bearbeitet</h3>
							<p>Ein Mitarbeiter kümmert sich um ihr Angebot. Sie erhalten in Kürze eine Mail mit den Informationen von uns.</p>
							<Link to="/">Home <Icon name="fas fa-chevron-right"/></Link>
						</Card>
					</Container>
				</Center>
			</section>
		</>
	)
}
