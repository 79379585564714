import React from 'react'
import Container from "../../components/Container/Container";
import Content from "../../components/Content/Content";

export default function Dsv() {
	return (
		<>
			<section>
				<Content fullscreen="true">
					<Container size="large" margin="true">
						<h2>Datenschutz</h2>
						<h3>Datenschutzerklärung</h3>
						<p>1.) Information über die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen</p>
						<p>Wir, die Firma IchWillWerben GmbH, Krampnitzer Weg 96, 14089 Berlin – Betreiber dieser Website – freuen uns,
						dass Sie
						diese
                    besuchen und danken für Ihr Interesse.</p>
						<p>Der ordnungsgemäße Umgang mit Ihren persönlichen Daten ist uns ein besonderes Anliegen. Daher
						informieren wir
						Sie
						im Folgenden gern über den Umgang mit Ihren persönlichen Daten. “Personenbezogene Daten” sind alle
						Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.
                </p>
						<p>Soweit nachstehend keine anderen Angaben gemacht werden, ist die Bereitstellung Ihrer
						personenbezogenen Daten
                    weder gesetzlich oder vertraglich vorgeschrieben, noch für einen Vertragsabschluss erforderlich.</p>
						<p>Sie sind zur Bereitstellung der Daten nicht verpflichtet. Eine Nichtbereitstellung hat keine Folgen.
						Dies
						gilt
                    nur soweit bei den nachfolgenden Verarbeitungsvorgängen keine anderweitige Angabe gemacht wird.</p>
						<p>Verantwortlicher für die Datenverarbeitung auf dieser Website im Sinne der Datenschutzgrundverordnung
						(DSGVO)
						ist
                    IchWillWerben GmbH<br />
                Amtsgericht Berlin (Charlottenburg) HRB 220331 B, Krampnitzer Weg 96, 14089 Berlin, Tel.: +49 (30) 629857-87,
								Fax: +49 (30) 629857-88, email: info@ichwillwerben.de .</p>
						<p>Der für die Verarbeitung personenbezogen Daten Verantwortliche ist diejenige natürliche oder
						juristische
						Person,
						die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
						personenbezogenen
						Daten
                    entscheidet.</p>
						<p>2.) Allgemeine Hinweise und Pflichtinformationen</p>
						<p>Widerruf Ihrer Einwilligung zur Datenverarbeitung</p>
						<p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können
						eine
						bereits
						erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
						Die
                    Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
						<p>Recht auf Datenübertragbarkeit</p>
						<p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
						automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
						aushändigen
						zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen,
						erfolgt
                    dies nur, soweit es technisch machbar ist.</p>
						<p>Auskunft, Sperrung, Löschung</p>
						<p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
						Auskunft
						über
						Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
						Datenverarbeitung
						und
						ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren
						Fragen zum
						Thema
						personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns
                    wenden.<br />
                  Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p>
						<p>SSL- bzw. TSL-Verschlüsselung</p>
						<p>Diese Website nutzt aus Sicherheitsgründen und zum Schutz der Übertragung personenbezogene Daten und
						anderer
						vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine SSL- bzw.
						TLS-Verschlüsselung. Sie können eine verschlüsselte Verbindung an der Zeichenfolge „https://“ und
						dem
                    Schloss-Symbol in Ihrer Browserzeile erkennen.</p>
						<p>3.) Datenerfassung beim Besuch unserer Website</p>
						<p>Server-Logfiles<br />
                  Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Es werden bei jedem
                  Zugriff
                  auf
                  unsere Website Nutzungsdaten durch Ihren Internetbrowser übermittelt und in Protokolldaten
                  (Server-Logfiles)
                    gespeichert. Zu diesen gespeicherten Daten gehören z.B.</p>
						<p>Name der aufgerufenen Seite<br />
                    übertragene Datenmenge<br />
                      Browsertyp und Browserversion<br />
                        verwendetes Betriebssystem<br />
                          Referrer URL<br />
                            Hostname des zugreifenden Rechners<br />
                              Datum / Uhrzeit der Serveranfrage<br />
                                IP-Adresse (ggf. anonymisiert)</p>
						<p>Diese Daten dienen ausschließlich der Gewährleistung eines störungsfreien Betriebs unserer Website
						und zur
						Verbesserung unseres Angebotes. Eine Zuordnung dieser Daten zu einer bestimmten Person ist nicht
						möglich.
						Wir
						behalten uns allerdings vor, die Server-Logfiles nachträglich zu überprüfen, sollten konkrete
						Anhaltspunkte
						auf
                    eine rechtswidrige Nutzung hinweisen.</p>
						<p>Cookies</p>
						<p>Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die im Internetbrowser bzw. vom
						Internetbrowser auf dem Computersystem eines Nutzers gespeichert werden. Ruft ein Nutzer eine
						Website auf,
						so
						kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine
						charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten
						Aufrufen der
						Website ermöglicht. Wir setzen Cookies zu dem Zweck ein, unser Angebot nutzerfreundlicher,
						effektiver und
						sicherer zu machen. Des Weiteren ermöglichen Cookie unseren Systemen, Ihren Browser auch nach einem
						Seitenwechsel zu erkennen und Ihnen Services anzubieten.Einige Funktionen unserer Internetseite
						können ohne
						den
						Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch
						nach einem
                    Seitenwechsel wiedererkannt wird.</p>
						<p>Wir verwenden auf unserer Website darüber hinaus Cookies zu dem Zweck, eine Analyse des
						Surfverhaltens
						unserer
                    Seitenbesucher zu ermöglichen.</p>
						<p>Des Weiteren verwenden wir Cookies zu dem Zweck, Seitenbesucher anschließend auf anderen Webseiten
						mit
						gezielter,
                    interessenbezogener Werbung anzusprechen.</p>
						<p>Die Verarbeitung erfolgt auf Grundlage des § 15 (3) TMG sowie Art. 6 (1) lit. f DSGVO aus dem
						berechtigten
                    Interesse an den oben genannten Zwecken.</p>
						<p>Die auf diese Weise von Ihnen erhobenen Daten werden durch technische Vorkehrungen pseudonymisiert.
						Eine
                    Zuordnung der Daten zu Ihrer Person ist daher nicht mehr möglich.</p>
						<p>Die Daten werden nicht gemeinsam mit sonstigen personenbezogenen Daten von Ihnen gespeichert.Sie
						haben das
						Recht
						aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen diese auf Art. 6 (1) f
						DSGVO
                    beruhende Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.</p>
						<p>Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die volle Kontrolle über die Verwendung
						von
						Cookies. Durch die Auswahl entsprechender technischerEinstellungen in Ihrem Internetbrowser können
						Sie die
						Speicherung der Cookies und Übermittlung der enthaltenen Daten verhindern. Bereits gespeicherte
						Cookies
						können
						jederzeit gelöscht werden. Wir weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht
						sämtliche
						Funktionen dieser Website vollumfänglich werden nutzen können.Unter den nachstehenden Links können
						Sie sich
						informieren, wie Sie die Cookies bei den wichtigsten Browsern verwalten (u.a. auch deaktivieren)
                    können.</p>
						<p>Chrome Browser: https://support.google.com/accounts/answer/61416?hl=de</p>
						<p>Internet Explorer:
						https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
                </p>
						<p>Mozilla Firefox: https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen</p>
						<p>Safari: https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                </p>
						<p>Kundenkonto / Registrierung auf der Website</p>
						<p>Bei der Eröffnung eines Kundenkontos erheben wir Ihre personenbezogenen Daten in dem dort angegeben
                    Umfang.<br />
                                  Die Datenverarbeitung dient dem Zweck, Ihr Einkaufserlebnis zu verbessern und die Bestellabwicklung
                                  zu
                                  vereinfachen. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO mit Ihrer
                                  Einwilligung. Sie
                                  können Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit
                                  der
                                  aufgrund
                    der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.<br />
                                    Ihr Kundenkonto wird anschließend gelöscht.</p>
						<p>Erhebung, Verarbeitung und Nutzung personenbezogener Daten bei Bestellungen</p>
						<p>Bei der Bestellung erheben und verwenden wir Ihre personenbezogenen Daten nur, soweit dies zur
						Erfüllung und
						Abwicklung Ihrer Bestellung sowie zur Bearbeitung Ihrer Anfragen erforderlich ist. Die
						Bereitstellung der
						Daten
						ist für den Vertragsschluss erforderlich. Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag
						geschlossen
						werden kann. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. b DSGVO und ist für die
						Erfüllung
						eines
                    Vertrags mit Ihnen erforderlich.</p>
						<p>Eine Weitergabe Ihrer Daten an Dritte ohne Ihre ausdrückliche Einwilligung erfolgt nicht. Ausgenommen
						hiervon
						sind lediglich unsere Dienstleistungspartner, die wir zur Abwicklung des Vertragsverhältnisses
						benötigen
						oder
						Dienstleister derer wir uns im Rahmen einer Auftragsverarbeitung bedienen. Neben den in den
						jeweiligen
						Klauseln
						dieser Datenschutzerklärung benannten Empfängern sind dies beispielsweise Empfänger folgender
						Kategorien:
						Versanddienstleister, Zahlungsdienstleister, Warenwirtschaftsdienstleister, Diensteanbieter für die
                    Bestellabwicklung, Webhoster, IT-Dienstleister und Dropshipping Händler.<br />
                                      In allen Fällen beachten wir strikt die gesetzlichen Vorgaben.<br />
                                        Der Umfang der Datenübermittlung beschränkt sich auf ein Mindestmaß.</p>
						<p>Erhebung und Verarbeitung bei Nutzung des Kontaktformulars</p>
						<p>Bei der Nutzung des Kontaktformulars erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse,
                    Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang.</p>
						<p>Die Datenverarbeitung dient dem Zweck der Kontaktaufnahme. Mit Absenden Ihrer Nachricht willigen Sie
						in die
						Verarbeitung der übermittelten Daten ein. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit.
						a DSGVO
						mit
                    Ihrer Einwilligung.</p>
						<p>Sie können Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die
						Rechtmäßigkeit der
                    aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.</p>
						<p>Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend
						gelöscht,
						sofern
                    Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.</p>
						<p>4. Analyse Tools und Werbung</p>
						<p>Google Analytics</p>
						<p>Wir verwenden auf unserer Website den Webanalysedienst Google Analytics der Google Inc. (1600
						Amphitheatre
                    Parkway, Mountain View, CA 94043, USA; “Google”).</p>
						<p>Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und ihrer Besucher. Dazu wird Google
						im
						Auftrag
						des Betreibers dieser Website die gewonnenen Informationen benutzen, um Ihre Nutzung der Website
						auszuwerten, um
						Reports über die Websiteaktivitäten zusammenzustellen und um weitere, mit der Websitenutzung und der
						Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im
						Rahmen von
						Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
                    zusammengeführt.</p>
						<p>Google Analytics verwendet Cookies, die eine Analyse der Benutzung der Website durch Sie ermöglichen.
						Die
						durch
						die Cookies erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen
						Server
						von
						Google in den USA übertragen und dort gespeichert. Auf dieser Website ist die IP-Anonymisierung
						aktiviert.
						Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in
						anderen
						Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
						Ausnahmefällen
						wird
						die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Ihre Daten
						werden
                    gegebenenfalls in die USA übermittelt.</p>
						<p>Für Datenübermittlungen in die USA ist ein Angemessenheitsbeschluss der Europäischen Kommission
						vorhanden.
						Die
						Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. f DSGVO aus dem berechtigten Interesse an der
						bedarfsgerechten und zielgerichteten Gestaltung der Website. Sie haben das Recht aus Gründen, die
						sich aus
						Ihrer
						besonderen Situation ergeben, jederzeit gegen diese auf Art. 6 (1) f DSGVO beruhende Verarbeitung
						Sie
                    betreffender personenbezogener Daten zu widersprechen.</p>
						<p>Sie können dazu die Speicherung der Cookies durch die Auswahl entsprechender technischer
						Einstellungen Ihrer
						Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
						gegebenenfalls nicht
						sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können des Weiteren die
						Erfassung
						der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
						IP-Adresse) an
						Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem
						folgenden Link
						verfügbare Browser-Plug-in herunterladen und installieren
                    https://tools.google.com/dlpage/gaoptout?hl=de</p>
						<p>Um die Erfassung durch Google Analytics geräteübergreifend zu verhindern können Sie einen
						Opt-Out-Cookie
						setzen.
						Opt-Out-Cookies verhindern die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website. Sie
                    müssen</p>
						<p>das Opt-Out auf allen genutzten Systemen und Geräten durchführen, damit dies umfassend wirkt. Wenn
						Sie hier
                    klicken, wird das Opt-Out-Cookie gesetzt: Google Analytics deaktivieren .</p>
						<p>Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter
                    https://www.google.com/analytics/terms/de.html bzw.<br />
                                          unter https://www.google.de/intl/de/policies/</p>
						<p>Remarketing- oder “Ähnliche Zielgruppen”-Funktion der Google Inc.</p>
						<p>Wir verwenden auf unserer Website die Remarketing- oder “Ähnliche Zielgruppen”- Funktion der Google
                    Inc.<br />
                                            (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; “Google”).</p>
						<p>Diese Funktion dient dem Zweck der Analyse des Besucherverhaltens und der Besucherinteressen.</p>
						<p>Zur Durchführung der Analyse der Websiten-Nutzung, welche die Grundlage für die Erstellung der
                    interessenbezogenen Werbeanzeigen bildet, setzt Google Cookies ein.</p>
						<p>Über die Cookies werden die Besuche der Website sowie anonymisierte Daten über die Nutzung der
						Website
						erfasst.
						Es erfolgt keine Speicherung von personenbezogenen Daten der Besucher der Website. Besuchen Sie
						nachfolgend
						eine
						andere Website im Google Display-Netzwerk werden Ihnen Werbeeinblendungen angezeigt, die mit hoher
                    Wahrscheinlichkeit zuvor aufgerufene Produkt- und Informationsbereiche berücksichtigen.</p>
						<p>Ihre Daten werden dabei gegebenenfalls auch in die USA übermittelt. Für Datenübermittlungen in die
						USA ist
						ein
                    Angemessenheitsbeschluss der Europäischen Kommission vorhanden.</p>
						<p>Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. f DSGVO aus dem berechtigten Interesse,
						die
						Besucher
						der Website zielgerichtet mit Werbung anzusprechen, indem für Besucher der Website des Anbieters
						personalisierte, interessenbezogene Werbung-Anzeigen geschaltet werden, wenn sie andere Websiten im
						Google
                    Display-Netzwerk besuchen.</p>
						<p>Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen
						diese auf
						Art.
                    6 (1) f DSGVO beruhende Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.</p>
						<p>Sie können dazu die Verwendung von Cookies durch Google dauerhaft deaktivieren, indem Sie dem
						nachfolgenden
						Link
						folgen und das dort bereitgestellte Plug-In herunterladen und installieren:
                    https://support.google.com/ads/answer/7395996?hl=de</p>
						<p>Alternativ können Sie die Verwendung von Cookies durch Drittanbieter deaktivieren, indem sie die
						Deaktivierungsseite der Netzwerkwerbeinitiative (Network Advertising Initiative) unter
						https://www.networkadvertising.org/choices/ aufrufen und die dort genannten weiterführenden
						Information zum
                    Opt-Out umsetzen.</p>
						<p>Nähere Informationen zu Google Remarketing sowie die dazugehörige Datenschutzerklärung finden Sie
						unter:
                    https://www.google.com/privacy/ads/</p>
						<p>Verwendung von Google Adwords Conversion-Tracking</p>
						<p>Wir verwenden auf unserer Website das Online-Werbeprogramm “Google AdWords” und in diesem Rahmen
                    Conversion-Tracking (Besuchsaktionsauswertung).</p>
						<p>Das Google Conversion Tracking ist ein Analysedienst der Google Inc. (1600 Amphitheatre Parkway,
						Mountain
						View,
						CA 94043, USA; “Google”). Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein Cookie
						für das
						Conversion-Tracking auf Ihrem Rechner abgelegt. Diese Cookies haben eine begrenzte Gültigkeit,
						enthalten
						keine
						personenbezogenen Daten und dienen somit nicht der persönlichen Identifizierung. Wenn Sie bestimmte
						Seiten
						unserer Website besuchen und das Cookie noch nicht abgelaufen ist, können Google und wir erkennen,
						dass Sie
						auf
						die Anzeige geklickt haben und zu dieser Seite weitergeleitet wurden. Jeder Google AdWords-Kunde
						erhält ein
						anderes Cookie. Somit besteht keine Möglichkeit, dass Cookies über die Websites von AdWords-Kunden
						nachverfolgt
                    werden können.</p>
						<p>Die Informationen, die mit Hilfe des Conversion-Cookie eingeholt werden, dienen dem Zweck
						Conversion-Statistiken
						zu erstellen. Hierbei erfahren wir die Gesamtanzahl der Nutzer, die auf eine unserer Anzeigen
						geklickt haben
						und
						zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Wir erhalten
						jedoch keine
						Informationen, mit denen sich Nutzer persönlich identifizieren lassen. Die Verarbeitung erfolgt auf
						Grundlage
						des Art. 6 (1) lit. f DSGVO aus dem berechtigten Interesse an zielgerichteter Werbung und der
						Analyse der
                    Wirkung und Effizienz dieser Werbung.</p>
						<p>Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen
						diese auf
						Art.
                    6 (1) f DSGVO beruhende Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.</p>
						<p>Dazu können Sie die Speicherung der Cookies durch die Auswahl entsprechender technischer
						Einstellungen Ihrer
						Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
						gegebenenfalls nicht
						sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie werden sodann nicht in
						die
                    Conversion-Tracking Statistiken aufgenommen.</p>
						<p>Des Weiteren können Sie in den Einstellungen für Werbung bei Google für Sie personalisierte Werbung
						deaktivieren.
                    Eine Anleitung dazu finden Sie unter https://support.google.com/ads/answer/2662922?hl=de</p>
						<p>Darüber hinaus können Sie die Verwendung von Cookies durch Drittanbieter deaktivieren, indem sie die
						Deaktivierungsseite der Netzwerkwerbeinitiative (Network Advertising Initiative) unter
						https://www.networkadvertising.org/choices/ aufrufen und die dort genannten weiterführenden
						Information zum
                    Opt-Out umsetzen.</p>
						<p>Weiterführende Informationen sowie die Datenschutzerklärung von Google finden Sie unter:
                    https://www.google.de/policies/privacy/</p>
						<p>Facebook Remarketing</p>
						<p>Wir verwenden auf unserer Website die Remarketing-Funktion “Custom Audiences” der Facebook Inc.<br />
                                            (1601 S. California Ave, Palo Alto, CA 94304, USA; “Facebook”).</p>
						<p>Diese Funktion dient dem Zweck die Besucher der Website zielgerichtet mit interessenbezogener Werbung
						im
						sozialen
                    Netzwerk Facebook anzusprechen.</p>
						<p>Dazu wurde auf der Website das Remarketing-Tag von Facebook implementiert. Über dieses Tag wird beim
						Besuch
						der
						Website eine direkte Verbindung zu den Facebook-Servern hergestellt. Hierdurch wird an den
						Facebook-Server
						übermittelt, welche unserer Seiten Sie besucht haben. Facebook ordnet diese Information Ihrem
						persönlichen
						Facebook-Benutzerkonto zu. Wenn Sie das soziale Netzwerk Facebook besuchen, werden Ihnen dann
						personalisierte,
                    interessenbezogene Facebook-Ads angezeigt.</p>
						<p>Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. f DSGVO aus dem berechtigen Interesse an
						dem oben
                    genannten Zweck.</p>
						<p>Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen
						diese auf
						Art.
                    6 (1) f DSGVO beruhende Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.</p>
						<p>Dazu können Sie die Remarketing-Funktion “Custom Audiences” hier () deaktivieren.</p>
						<p>Nähere Informationen zur Erhebung und Nutzung der Daten durch Facebook, über Ihre diesbezüglichen
						Rechte und
						Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von Facebook
						unter
                    https://www.facebook.com/about/privacy/.</p>
						<p>Google AdSense</p>
						<p>Wir verwenden auf unserer Website die AdSense-Funktion der Google Inc.<br />
                                              (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; “Google”).</p>
						<p>Diese Funktion dient dem Zweck Werbeflächen auf der Website zu vermieten und auf diesen die Besucher
						der
						Website
                    zielgerichtet mit interessenbezogener Werbung anzusprechen.</p>
						<p>Mittels dieser Funktion werden den Besuchern der Website des Anbieters personalisierte,
						interessenbezogene
						Werbung-Anzeigen aus dem Google Display-Netzwerk geschaltet. Dabei verwendet Google Cookies, die
						eine
						Analyse
						der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über
						Ihre
						Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und
						dort
						gespeichert. Google wird diese Informationen gegebenenfalls an Dritte übertragen, sofern dies
						gesetzlich
						vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in
						keinem
						Fall
                    Ihre IP-Adresse mit anderen Daten von Google in Verbindung bringen.</p>
						<p>Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. f DSGVO aus dem berechtigten Interesse an
						dem oben
                    genannten Zweck.</p>
						<p>Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen
						diese auf
						Art.
                    6 (1) f DSGVO beruhende Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.</p>
						<p>Sie können dazu die Verwendung von Cookies durch Google dauerhaft deaktivieren, indem Sie dem
						nachfolgenden
						Link
						folgen und das dort bereitgestellte Plug-In herunterladen und installieren:
                    https://support.google.com/ads/answer/7395996?hl=de.</p>
						<p>Des Weiteren können Sie die Verwendung von Cookies durch Drittanbieter deaktivieren, indem sie die
						Deaktivierungsseite der Netzwerkwerbeinitiative (Network Advertising Initiative) unter
						https://www.networkadvertising.org/choices/ aufrufen und die dort genannten weiterführenden
						Information zum
                    Opt-Out umsetzen.</p>
						<p>Sie können die Speicherung der Cookies auch durch eine entsprechende Einstellung Ihrer
						Browser-Software
						verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
						Funktionen
                    dieser Website vollumfänglich werden nutzen können.</p>
						<p>Nähere Informationen sowie die Datenschutzerklärung von Google finden Sie unter:
                    https://www.google.com/policies/technologies/ads/ ,</p>
						<p>https://www.google.de/policies/privacy/</p>
						<p>Verwendung von Google reCAPTCHA</p>
						<p>Wir verwenden auf unserer Website den Dienst reCAPTCHA der Google Inc. (1600 Amphitheatre Parkway,
						Mountain
						View,
                    CA 94043, USA; “Google”).</p>
						<p>Die Abfrage dient dem Zweck der Unterscheidung, ob die Eingabe durch einen Menschen oder durch
						automatisierte,
                    maschinelle Verarbeitung erfolgt.</p>
						<p>Die Abfrage schließt den Versand der IP-Adresse und ggf. weiterer von Google für den Dienst reCAPTCHA
						benötigter
                    Daten an Google ein.</p>
						<p>Zu diesem Zweck wird Ihre Eingabe an Google übermittelt und dort weiter verwendet. Ihre IP-Adresse
						wird von
						Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
						des
						Abkommens
						über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse
						an
						einen
                    Server von Google in den</p>
						<p>USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
						Informationen
                    benutzen, um Ihre Nutzung dieses Dienstes auszuwerten.</p>
						<p>Die im Rahmen von reCaptcha von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten
						von Google
						zusammengeführt. Ihre Daten werden dabei gegebenenfalls auch in die USA übermittelt. Für
						Datenübermittlungen
						in
						die USA ist ein Angemessenheitsbeschluss der Europäischen Kommission, das “Privacy Shield”,
						vorhanden.
						Google
						nimmt am “Privacy Shield” teil und hat sich den Vorgaben unterworfen. Mit Betätigen der Abfrage
						willigen Sie
						in
						die Verarbeitung Ihrer Daten ein. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO
						mit
						Ihrer
                    Einwilligung.</p>
						<p>Sie können Ihre Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der
						Einwilligung
						bis
                    zum Widerruf erfolgten Verarbeitung berührt wird.</p>
						<p>Nähere Informationen zu Google reCAPTCHA sowie die dazugehörige Datenschutzerklärung finden Sie
						unter:
                    https://www.google.com/privacy/ads/ (https://policies.google.com/technologies/ads?hl=de)</p>
						<p>5. Nutzung Ihrer Daten zur Direktwerbung</p>
						<p>Verwendung der E-Mail-Adresse für die Zusendung von Newslettern</p>
						<p>Wir nutzen Ihre E-Mail-Adresse unabhängig von der Vertragsabwicklung ausschließlich für eigene
						Werbezwecke
						zum
                    Newsletterversand, sofern Sie dem ausdrücklich zugestimmt haben.</p>
						<p>Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung. Sie können
						die
						Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis
						zum
						Widerruf
						erfolgten Verarbeitung berührt wird. Sie können dazu den Newsletter jederzeit unter Nutzung des
						entsprechenden
						Links im Newsletter oder durch Mitteilung an uns abbestellen. Ihre E-Mail-Adresse wird danach aus
						dem
						Verteiler
                    entfernt.</p>
						<p>Ihre Daten werden dabei an einen Dienstleister für E-Mail-Marketing im Rahmen einer
						Auftragsverarbeitung
                    weitergegeben. Eine Weitergabe an sonstige Dritte erfolgt nicht.</p>
						<p>Verwendung der E-Mail-Adresse für die Zusendung von Direktwerbung</p>
						<p>Wir nutzen Ihre E-Mail-Adresse, die wir im Rahmen des Verkaufes einer Ware oder Dienstleistung
						erhalten
						haben,
						für die elektronische Übersendung von Werbung für eigene Waren oder Dienstleistungen, die denen
						ähnlich
						sind,
						die Sie bereits bei uns erworben haben, soweit Sie dieser Verwendung nicht widersprochen haben. Die
						Bereitstellung der E-Mail-Adresse ist für den Vertragsschluss erforderlich. Eine Nichtbereitstellung
						hat zur
						Folge, dass kein Vertrag geschlossen werden kann. Die Verarbeitung erfolgt auf Grundlage des Art. 6
						(1) lit.
						f
						DSGVO aus dem berechtigtem Interesse an Direktwerbung. Sie können dieser Verwendung Ihrer
						E-Mail-Adresse
                    jederzeit durch Mitteilung an uns widersprechen.</p>
						<p>Die Kontaktdaten für die Ausübung des Widerspruchs finden Sie im Impressum. Sie können auch den dafür
						vorgesehenen Link in der Werbe-E-Mail nutzen. Hierfür entstehen keine anderen als die
						Übermittlungskosten
						nach
                    den Basistarifen.</p>
						<p>Versand des E-Mail-Newsletters an Bestandskunden</p>
						<p>Wenn Sie uns Ihre E-Mailadresse beim Kauf von Waren bzw. Dienstleistungen zur Verfügung gestellt
						haben,
						behalten
						wir uns vor, Ihnen regelmäßig Angebote zu ähnlichen Waren bzw. Dienstleistungen, wie den bereits
						gekauften,
						aus
						unserem Sortiment per E-Mail zuzusenden. Hierfür müssen wir gemäß § 7 Abs. 3 UWG keine gesonderte
						Einwilligung
						von Ihnen einholen. Die Datenverarbeitung erfolgt insoweit allein auf Basis unseres berechtigten
						Interesses
						an
						personalisierter Direktwerbung gemäß Art. 6 Abs. 1 lit. f DSGVO. Haben Sie der Nutzung Ihrer
						E-Mailadresse
						zu
						diesem Zweck anfänglich widersprochen, findet ein Mailversand unsererseits nicht statt. Sie sind
						berechtigt,
						der
						Nutzung Ihrer E-Mailadresse zu dem vorbezeichneten Werbezweck jederzeit mit Wirkung für die Zukunft
						durch
						eine
						Mitteilung an den zu Beginn genannten Verantwortlichen zu widersprechen. Hierfür fallen für Sie
						lediglich
						Übermittlungskosten nach den Basistarifen an. Nach Eingang Ihres Widerspruchs wird die Nutzung Ihrer
                    E-Mailadresse zu Werbezwecken unverzüglich eingestellt.</p>
						<p>Newsletter per email-Marketing-Tool Klick-Tipp</p>
						<p>Diese Website nutzt Klick-Tipp für den Versand von Newslettern. Anbieter ist die KLICK-TIPP LIMITED,
						15
						Cambridge
                    Court, 210 Shepherd’s Bush Road, London W6 7NJ, Vereinigtes Königreich.</p>
						<p>Klick-Tipp ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert und analysiert werden
						kann.
						Die
						von Ihnen zum Zwecke des Newsletterbezugs eingegeben Daten werden auf den Servern von Klick-Tipp
						gespeichert.
                </p>
						<p>Datenanalyse durch Klick-Tipp</p>
						<p>Wenn wir Newsletter mit Hilfe von Klick-Tipp versenden, können wir feststellen, ob eine
						Newsletter-Nachricht
                    geöffnet und welche Links ggf. angeklickt wurden.</p>
						<p>Klick-Tipp ermöglicht es uns auch, die Newsletter-Empfänger anhand verschiedener Kategorien zu
						unterteilen
						(sog.
						Tagging). Dabei lassen sich die Newsletterempfänger z.B. nach Geschlecht, persönlichen Vorlieben
						(z.B.
						Vegetarier oder Nicht-Vegetarier) oder Kundenbeziehung (z.B. Kunde oder potenzieller Kunde)
						unterteilen. Auf
						diese Weise lassen sich die Newsletter besser an die jeweiligen Zielgruppen anpassen. Nähere
						Informationen
                    erhalten Sie unter: https://www.klick-tipp.com und https://www.klick-tipp.com/handbuch.</p>
						<p>Wenn Sie keine Analyse durch Klick-Tipp wollen, müssen Sie daher den Newsletter abbestellen. Hierfür
						stellen
						wir
						in jeder Newsletternachricht einen entsprechenden Link zur Verfügung. Des Weiteren können Sie den
						Newsletter
                    auch direkt auf der Website abbestellen.</p>
						<p>Rechtsgrundlage</p>
						<p>Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie
						können diese
						Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bereits erfolgten
						Datenverarbeitungsvorgänge
						bleibt
                    vom Widerruf unberührt.</p>
						<p>Speicherdauer</p>
						<p>Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer
						Austragung
						aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters sowohl von unseren Servern
						als auch
						von
						den Servern von Klick-Tipp gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden (z.B.
                    E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon unberührt.</p>
						<p>Näheres entnehmen Sie den Datenschutzbestimmungen von Klick-Tipp unter:
                    https://www.klick-tipp.com/datenschutzerklaerung.</p>
						<p>Abschluss eines Vertrags über Auftragsdatenverarbeitung</p>
						<p>Wir haben einen Vertrag über die Auftragsdatenverarbeitung mit Klick-Tipp abgeschlossen, in dem wir
						Klick-Tipp
                    verpflichten, die Daten unserer Kunden zu schützen und sie nicht an Dritte weiterzugeben.</p>
						<p>6.) Datenerhebung bei Verfassung eines Kommentars oder Blog-Eintrags</p>
						<p>Bei der Kommentierung eines Artikels oder eines Beitrages erheben wir Ihre personenbezogenen Daten
						(Name,
						E-Mail-Adresse, Kommentartext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die
						Verarbeitung dient
						dem
                    Zweck, eine Kommentierung zu ermöglichen und Kommentare anzuzeigen.</p>
						<p>Mit Absenden des Kommentars willigen Sie in die Verarbeitung der übermittelten Daten ein. Die
						Verarbeitung
                    erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung.</p>
						<p>Sie können die Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die
						Rechtmäßigkeit der
						aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihre
						personenbezogenen Daten
                    werden anschließend gelöscht.</p>
						<p>Bei Veröffentlichung Ihres Kommentars wird der von Ihnen angegebene Name und die von Ihnen
						mitgeteilte
                    E-Mail-Adresse veröffentlicht.</p>
						<p>Darüber hinaus wird bei Abgabe des Kommentars Ihre IP-Adresse gespeichert zu dem Zweck, einen
						Missbrauch der
						Kommentarfunktion zu verhindern und die Sicherheit unserer informationstechnischen Systeme
						sicherzustellen.
						Mit
                    Absenden des Kommentars willigen Sie in die Verarbeitung der übermittelten Daten ein.</p>
						<p>Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung. Sie können
						die
						Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund
						der
						Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihre IP-Adresse wird anschließend
						gelöscht.
                </p>
						<p>7.) Käufersiegel-Bewertungssystem</p>
						<p>Wir verwenden auf unserer Website das Käufersiegel-Bewertungssystem der Händlerbund Management AG
						(Torgauer
                    Straße 233 B, 04347 Leipzig).</p>
						<p>Nach Ihrer Bestellung möchten wir Sie bitten, Ihren Kauf bei uns zu bewerten und zu kommentieren. Zu
						diesem
						Zweck
						werden Sie von uns im Rahmen der Vertragsabwicklung angeschrieben, wobei wir uns hierbei des
						technischen
						Systems
                    des Anbieters des Käufersiegel-Bewertungstools im Rahmen einer Auftragsverarbeitung bedienen.</p>
						<p>Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. f DSGVO aus dem berechtigten Interesse an
                    wahrheitsgemäßen, verifizierten Bewertungen unserer Leistungen.</p>
						<p>Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen
						diese auf
						Art.
						6 (1) lit. f DSGVO beruhende Verarbeitung Sie betreffender personenbezogener Daten durch eine
						Mitteilung an
						uns
						zu widersprechen. Ihre E-Mail-Adresse wird dabei nur zu diesem Zweck und insbesondere nicht zu
						weitergehender
                    Werbung</p>
						<p>benutzt und auch nicht an sonstige Dritte weitergegeben. Die in diesem Zusammenhang im technischen
						System des
						Käufersiegel-Bewertungstools gespeicherten personenbezogenen Daten werden 3 Monate nach der zur
						Bewertung
                    erfassten Warenlieferung gelöscht.</p>
						<p>8.) Datenverarbeitung zur Bestellabwicklung</p>
						<p>Zur Abwicklung Ihrer Bestellung arbeiten wir mit dem, den nachstehenden Dienstleistern zusammen, die
						uns ganz
						oder teilweise bei der Durchführung geschlossener Verträge unterstützen. An diese Dienstleister
						werden nach
                    Maßgabe der folgenden Informationen gewisse personenbezogene Daten übermittelt.</p>
						<p>Die von uns erhobenen personenbezogenen Daten werden im Rahmen der Vertragsabwicklung an das mit der
						Lieferung
						beauftragte Transportunternehmen weitergegeben, soweit dies zur Lieferung der Ware erforderlich ist.
						Ihre
						Zahlungsdaten geben wir im Rahmen der Zahlungsabwicklung an das beauftragte Kreditinstitut weiter,
						sofern
						dies
						für die Zahlungsabwicklung erforderlich ist. Sofern Zahlungsdienstleister eingesetzt werden,
						informieren wir
						hierüber nachstehend explizit. Die Rechtsgrundlage für die Weitergabe der Daten ist hierbei Art. 6
						Abs. 1
						lit. b
                    DSGVO.</p>
						<p>Weitergabe personenbezogener Daten an Versanddienstleister</p>
						<p>– DHL<br />
                                                Erfolgt die Zustellung der Ware durch den Transportdienstleister DHL (Deutsche Post AG,
                                                Charles-de-Gaulle-Straße
                                                20, 53113 Bonn), so geben wir Ihre E-Mail-Adresse gemäß Art. 6 Abs. 1 lit. a DSGVO vor der
                                                Zustellung der
                                                Ware
                                                zum Zwecke der Abstimmung eines Liefertermins bzw. zur Lieferankündigung an DHL weiter, sofern Sie
                                                hierfür
                                                im
                                                Bestellprozess Ihre ausdrückliche Einwilligung erteilt haben. Anderenfalls geben wir zum Zwecke der
                                                Zustellung
                                                gemäß Art. 6 Abs. 1 lit. b DSGVO nur den Namen des Empfängers und die Lieferadresse an DHL weiter.
                                                Die
                                                Weitergabe erfolgt nur, soweit dies für die Warenlieferung erforderlich ist. In diesem Fall ist eine
                                                vorherige
                    Abstimmung des Liefertermins mit DHL bzw. die Lieferankündigung nicht möglich.<br />
                                                  Die Einwilligung kann jederzeit mit Wirkung für die Zukunft gegenüber dem oben bezeichneten
                                                  Verantwortlichen
                    oder gegenüber dem Transportdienstleister DHL widerrufen werden.</p>
						<p>– Paypal<br />
                                                  Bei Zahlung via PayPal, Kreditkarte via PayPal, Lastschrift via PayPal oder – falls angeboten –
                                                  “Kauf auf
                                                  Rechnung” oder „Ratenzahlung“ via PayPal geben wir Ihre Zahlungsdaten im Rahmen der
                                                  Zahlungsabwicklung an
                                                  die
                                                  PayPal (Europe) S.a.r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg (nachfolgend
                                                  “PayPal”),
                                                  weiter. Die Weitergabe erfolgt gemäß Art. 6 Abs. 1 lit. b DSGVO und nur insoweit, als dies für die
                    Zahlungsabwicklung erforderlich ist.<br />
                                                    PayPal behält sich für die Zahlungsmethoden Kreditkarte via PayPal, Lastschrift via PayPal oder –
                                                    falls
                                                    angeboten – “Kauf auf Rechnung” oder „Ratenzahlung“ via PayPal die Durchführung einer
                                                    Bonitätsauskunft vor.
                                                    Hierfür werden Ihre Zahlungsdaten gegebenenfalls gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis des
                                                    berechtigten
                                                    Interesses von PayPal an der Feststellung Ihrer Zahlungsfähigkeit an Auskunfteien weitergegeben. Das
                                                    Ergebnis
                                                    der Bonitätsprüfung in Bezug auf die statistische Zahlungsausfallwahrscheinlichkeit verwendet PayPal
                                                    zum
                                                    Zwecke
                                                    der Entscheidung über die Bereitstellung der jeweiligen Zahlungsmethode. Die Bonitätsauskunft kann
                                                    Wahrscheinlichkeitswerte enthalten (sog. Score-Werte). Soweit Score-Werte in das Ergebnis der
                                                    Bonitätsauskunft
                                                    einfließen, haben diese ihre Grundlage in einem wissenschaftlich anerkannten
                                                    mathematisch-statistischen
                                                    Verfahren. In die Berechnung der Score-Werte fließen unter anderem, aber nicht ausschließlich,
                                                    Anschriftendaten
                                                    ein. Weitere datenschutzrechtliche Informationen, unter anderem zu den verwendeten Auskunfteien,
                                                    entnehmen
                                                    Sie
                    bitte der Datenschutzerklärung von PayPal: https://www.paypal.com/de/webapps/mpp/ua/privacy-full<br />
                                                      Sie können dieser Verarbeitung Ihrer Daten jederzeit durch eine Nachricht an PayPal widersprechen.
                                                      Jedoch
                                                      bleibt
                                                      PayPal ggf. weiterhin berechtigt, Ihre personenbezogenen Daten zu verarbeiten, sofern dies zur
                                                      vertragsgemäßen
                    Zahlungsabwicklung erforderlich ist.</p>
						<p>– STRIPE</p>
						<p>Bei Auswahl der Zahlungsart „STRIPE“ erfolgt die Zahlungsabwicklung über den Paymentdienstleister
						Stripe
						Payments
						Europe Ltd, Block 4, Harcourt Centre, Harcourt Road, Dublin 2, Ireland, an den wir Ihre im Rahmen
						des
						Bestellvorgangs mitgeteilten Informationen nebst den Informationen über Ihre Bestellung (Name,
						Anschrift,
						Kontonummer, Bankleitzahl, evtl. Kreditkartennummer, Rechnungsbetrag, Währung und
						Transaktionsnummer)
						weitergeben. Die Weitergabe Ihrer Daten erfolgt ausschließlich zum Zwecke der Zahlungsabwicklung mit
						dem
						Payment-Dienstleister Stripe Payments Europe Ltd. Nähere Informationen zum Datenschutz von Stripe
						finden Sie
                    unter der URL https://stripe.com/de/terms</p>
						<p>9.) Plugins und Tools</p>
						<p>Verwendung von YouTube</p>
						<p>Wir verwenden auf unserer Website die Funktion zur Einbettung von YouTube-Videos der YouTube LLC.
						(901 Cherry
                    Ave., San Bruno, CA 94066, USA; “YouTube”).</p>
						<p>YouTube ist ein mit der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
						“Google”)
                    verbundenes Unternehmen.</p>
						<p>Die Funktion zeigt bei YouTube hinterlegte Videos in einem iFrame auf der Website an. Dabei ist die
						Option
                    “Erweiterter Datenschutzmodus” aktiviert.</p>
						<p>Dadurch werden von YouTube keine Informationen über die Besucher der Website gespeichert. Erst wenn
						Sie sich
						ein
                    Video ansehen, werden Informationen darüber an YouTube übermittelt und dort gespeichert.</p>
						<p>Nähere Informationen zur Erhebung und Nutzung der Daten durch YouTube und Google, über Ihre
						diesbezüglichen
						Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von
						YouTube
                    https://www.youtube.com/t/privacy .</p>
						<p>Dauer der Speicherung</p>
						<p>Nach vollständiger Vertragsabwicklung werden die Daten zunächst für die Dauer der
						Gewährleistungsfrist,
						danach
						unter Berücksichtigung gesetzlicher, insbesondere steuer- und handelsrechtlicher
						Aufbewahrungsfristen
						gespeichert und dann nach Fristablauf gelöscht, sofern Sie der weitergehenden Verarbeitung und
						Nutzung nicht
                    zugestimmt haben.</p>
						<p>Nutzung von Vimeo-Plugins</p>
						<p>Wir nutzen für die Einbindung von Videos u.a. den Anbieter Vimeo. Vimeo wird betrieben von Vimeo, LLC
						mit
                    Hauptsitz in 555 West 18th Street, New York, New York 10011.</p>
						<p>Auf einigen unserer Internetseiten verwenden wir Plugins des Anbieters Vimeo. Wenn Sie die mit einem
						solchen
						Plugin versehenen Internetseiten unserer Internetpräsenz aufrufen, wird eine Verbindung zu den
						Vimeo-Servern
						hergestellt und dabei das Plugin dargestellt. Hierdurch wird an den Vimeo-Server übermittelt, welche
						unserer
						Internetseiten Sie besucht haben. Sind Sie dabei als Mitglied bei Vimeo eingeloggt, ordnet Vimeo
						diese
                    Information Ihrem persönlichen Benutzerkonto zu.</p>
						<p>Bei Nutzung des Plugins wie z.B. Anklicken des Start-Buttons eines Videos wird diese Information
						ebenfalls
						Ihrem
						Benutzerkonto zugeordnet. Sie können diese Zuordnung verhindern, indem Sie sich vor der Nutzung
						unserer
						Internetseite aus ihrem Vimeo-Benutzerkonto abmelden und die entsprechenden Cookies von Vimeo
                    löschen.</p>
						<p>Weitere Informationen zur Datenverarbeitung und Hinweise zum Datenschutz durch Vimeo finden Sie unter
                    https://vimeo.com/privacy .</p>
						<p>10.) Rechte der betroffenen Person</p>
						<p>Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem Verantwortlichen hinsichtlich der
						Verarbeitung
						Ihrer
						personenbezogenen Daten umfassende Betroffenenrechte (Auskunfts- und Interventionsrechte), über die
						wir Sie
                    nachstehend informieren:</p>
						<p>Auskunftsrecht gemäß Art. 15 DSGVO:<br />
                                                      Sie haben insbesondere ein Recht auf Auskunft über Ihre von uns verarbeiteten personenbezogenen
                                                      Daten, die
                                                      Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empfänger oder
                                                      Kategorien
                                                      von
                                                      Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer
                                                      bzw. die
                                                      Kriterien für die Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung,
                                                      Löschung,
                                                      Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer
                                                      Aufsichtsbehörde,
                                                      die
                                                      Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer
                                                      automatisierten
                                                      Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftige Informationen über die
                                                      involvierte
                                                      Logik
                                                      und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung,
                                                      sowie Ihr
                                                      Recht
                                                      auf Unterrichtung, welche Garantien gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittländer
                                                      bestehen;
                </p>
						<p>Recht auf Berichtigung gemäß Art. 16 DSGVO:<br />
                                                        Sie haben ein Recht auf unverzügliche Berichtigung Sie betreffender unrichtiger Daten und/oder
                                                        Vervollständigung
                    Ihrer bei uns gespeicherten unvollständigen Daten;</p>
						<p>Recht auf Löschung gemäß Art. 17 DSGVO:<br />
                                                          Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten bei Vorliegen der Voraussetzungen
                                                          des Art.
                                                          17
                                                          Abs. 1 DSGVO zu verlangen. Dieses Recht besteht jedoch insbesondere dann nicht, wenn die
                                                          Verarbeitung zur
                                                          Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen
                                                          Verpflichtung,
                                                          aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von
                                                          Rechtsansprüchen
                    erforderlich ist;</p>
						<p>Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO:<br />
                                                            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen,
                                                            solange
                                                            die
                                                            von Ihnen bestrittene Richtigkeit Ihrer Daten überprüft wird, wenn Sie eine Löschung Ihrer Daten
                                                            wegen
                                                            unzulässiger Datenverarbeitung ablehnen und stattdessen die Einschränkung der Verarbeitung Ihrer
                                                            Daten
                                                            verlangen, wenn Sie Ihre Daten zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                                                            benötigen,
                                                            nachdem wir diese Daten nach Zweckerreichung nicht mehr benötigen oder wenn Sie Widerspruch aus
                                                            Gründen
                                                            Ihrer
                                                            besonderen Situation eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gründe
                                                            überwiegen;
                </p>
						<p>Recht auf Unterrichtung gemäß Art. 19 DSGVO:<br />
                                                              Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem
                                                              Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie
                                                              betreffenden
                                                              personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder
                                                              Einschränkung
                                                              der
                                                              Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem
                                                              unverhältnismäßigen
                    Aufwand verbunden. Ihnen steht das Recht zu, über diese Empfänger unterrichtet zu werden.</p>
						<p>Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO:<br />
                                                                Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
                                                                strukturierten,
                                                                gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen
                                                                Verantwortlichen
                                                                zu
                    verlangen, soweit dies technisch machbar ist;</p>
						<p>Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DSGVO:<br />
                                                                  Sie haben das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit
                                                                  Wirkung
                                                                  für
                                                                  die Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten unverzüglich
                                                                  löschen,
                                                                  sofern
                                                                  eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung
                                                                  gestützt werden
                                                                  kann. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis
                                                                  zum
                                                                  Widerruf
                    erfolgten Verarbeitung nicht berührt;</p>
						<p>Recht auf Beschwerde gemäß Art. 77 DSGVO:<br />
                                                                    Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
                                                                    die
                                                                    DSGVO
                                                                    verstößt, haben Sie – unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
                                                                    Rechtsbehelfs –
                                                                    das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres
                                                                    Aufenthaltsortes,
                    Ihres Arbeitsplatzes oder des Ortes des mutmaßlichen Verstoßes.</p>
						<p>Außerdem steht Ihnen nach Art. 21 (1) DSGVO ein Widerspruchsrecht gegen die Verarbeitungen zu, die
						auf Art. 6
						(1)
                    f DSGVO beruhen, sowie gegen die Verarbeitung zum Zwecke von Direktwerbung.</p>
						<p>Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten finden Sie in unserem Impressum.</p>
						<p>Beschwerderecht bei der Aufsichtsbehörde</p>
						<p>Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der
						Ansicht
						sind,
                    dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.</p>
						<p>11.) Dauer der Speicherung personenbezogener Daten</p>
						<p>Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen gesetzlichen
						Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen). Nach Ablauf der Frist
						werden
						die
						entsprechenden Daten routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder
						Vertragsanbahnung
						erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung
						fortbesteht.
                </p>
						<p>12.) Haftungsbeschränkung und Haftungsfreistellung</p>
						<p>Haftungsbeschränkung</p>
						<p>(1) Das Unternehmen haftet nur für Schäden, die von ihm und seinen Mitarbeitern vorsätzlich oder grob
						fahrlässig
                    begangen wurden.</p>
						<p>(2) Soweit dem Unternehmen keine vorsätzliche Vertragsverletzung angelastet wird, ist die
						Schadensersatzhaftung
                    auf den vorhersehbaren, typischerweise eintretenden Schaden begrenzt.</p>
						<p>(3) Die Haftungsbeschränkungen in diesen Allgemeinen Geschäftsbedingungen gelten nicht für
						Schadenersatzansprüche
						aus Verletzung des Lebens, des Körpers oder der Gesundheit oder aus der Verletzung
						vertragswesentlicher
						Pflichten (Kardinalspflichten), insoweit haftet das Unternehmen für jeden von ihm oder seinen
						Erfüllungsgehilfen
						vorsätzlich oder fahrlässig verursachten Schaden. Darüber hinaus haftet das Unternehmen aufgrund
						sonstiger
                    zwingender gesetzlicher Vorschriften.</p>
						<p>Haftungsfreistellung</p>
						<p>(1) Der Kunde versichert, dass er die Schnittstelle rechtskonform und insbesondere im Einklang mit
						dem
						geltenden
                    Datenschutzrecht einsetzen wird.</p>
						<p>(2) Sofern Dritte das Unternehmen mit der Behauptung in Anspruch nehmen, dass die Nutzung der
						Schnittstelle
                    Rechte Dritter verletzt, ist der Kunde verpflichtet,</p>
						<p>a. das Unternehmen hinsichtlich jedweder Haftung schadlos zu halten,</p>
						<p>b. dem Unternehmen jedwede Aufwendungen und sonstigen Kosten zu ersetzen, die wegen der
						Inanspruchnahme
                    entstanden sind,</p>
						<p>c. hinsichtlich der Aufwendungen und sonstigen Kosten i.S.d. von lit. b, insbesondere Kosten der
                    Rechtsverteidigung, das Unternehmen einen angemessenen Vorschuss zu leisten,</p>
						<p>d. das Unternehmen entsprechend der Grundsätze von Treu und Glauben mit sämtlichen Informationen und
						Unterlagen
                    zu versorgen, die zur Rechtsverteidigung erforderlich sind.</p>
						<p>(3) Sofern und soweit der Kunde die Inanspruchnahme des Unternehmens nicht zu vertreten hat,
						entfallen die
                    Pflichten nach Absatz 2.</p>
						<p>(4) Alle weitergehenden Ansprüche des Webdesigners bleiben hiervon unberührt.</p>
						<p>Letzte Aktualisierung: 18.12.2020</p>
					</Container>
				</Content>
			</section>
		</>
	)
}
