import { Checkbox, ListItem, ListItemIcon, } from '@material-ui/core'
import React from 'react'
import Image from '../Image/Image'
import styles from './MapListSlot.module.scss'

export default function MapListSlot({ panel, checked, handleToggle }) {
  
  let panelImage = "/images/panel_default.png"
  if (panel.imageUrl) {
    panelImage = panel.imageUrl
  }

  const panelFormat = panel.displayFormat === "portrait" ? "Hochformat" : "Querformat";

  return (
    <ListItem key={panel._id} className={styles.list_item} role={undefined} button onClick={handleToggle(panel._id)}>
      <ListItemIcon>
        <Checkbox
          checked={checked.indexOf(panel._id) !== -1}
          tabIndex={-1}
          disableRipple={true}
          inputProps={{ 'aria-labelledby': panel.name }}
        />
      </ListItemIcon>
      <div className={styles.panel}>
        <Image className={styles.image} src={panelImage} />
        <div className={styles.text}>
          <div className={styles.tags}>
            <p>{panel.size}'</p>
            <p>{panelFormat}</p>
          </div>
          <h5 className={styles.name}>{panel.name}</h5>
          <p className={styles.address}>{panel.address}</p>
          <h5 className={styles.price}>ab {panel.price} € pro Monat</h5>
        </div>
      </div>
    </ListItem>
  )
}
