import React from 'react'
import { Button as MaterialButton, withStyles, } from '@material-ui/core';

const ButtonStylized = withStyles({
    root: {
        boxShadow: 'none',
        backgroundColor: '#FFFFFF',
        textTransform: 'none',
        padding: '0.3em 1.6em',
        color: 'black',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#FFFFFF',
            filter: 'brightness(85%)',
        },
    },
})(MaterialButton);


export default function Button(props) {
    return (
        <ButtonStylized {...props}/>
    )
}