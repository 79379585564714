import React, { useEffect, useState } from "react";
import MapList from "./MapList";
import MapMarker from "./MapMarker";
import MapWrapper from "./MapWrapper";
import styles from "./Map.module.scss";
import { useHistory } from "react-router";
import { useLocalStorage } from "../../hooks/useStorage";

const LOCAL_STORAGE_KEY = "IwwCMS.map.checked";

export default function Map(props) {
  const [panels, setPanels] = useState([]);
  const history = useHistory();
  const [checked, setChecked] = useLocalStorage(LOCAL_STORAGE_KEY, []);

  const checkedPanels = panels.filter((panel) => checked.includes(panel._id));

  useEffect(() => {
    fetch("https://cms.ichwillwerben.de/api/v1/panels/map")
      .then((response) => response.json())
      .then((data) => {
        setPanels(data);
      });
  }, []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggleAll = (value) => () => {
    if (checked.length > 0) {
      setChecked([]);
    } else {
      const IDs = panels.map((panel) => panel._id);
      setChecked(IDs);
    }
  };

  const handleCheckout = (value) => () => {
    if (checked.length > 0) {
      history.push({
        pathname: "/buchen",
        state: {
          checkedPanels: checkedPanels,
        },
      });
    }
  };

  return (
    <>
      <div className={styles.map_container}>
        <MapWrapper {...props}>
          {panels.map((panel) => (
            <MapMarker
              panel={panel}
              checked={checked}
              handleToggle={handleToggle}
              key={panel.name}
            />
          ))}
        </MapWrapper>
        {props.showMenu && (
          <div className={styles.map_list}>
            <MapList
              panels={panels}
              checked={checked}
              checkedPanels={checkedPanels}
              handleToggle={handleToggle}
              handleToggleAll={handleToggleAll}
              handleCheckout={handleCheckout}
            />
          </div>
        )}
      </div>
    </>
  );
}
