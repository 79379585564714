import React from 'react'
import PropTypes from 'prop-types'
import styles from './Text.module.scss'

function Text(props) {

    let classList = ""//styles.text;

    function addClass(classToAdd){
        classList = classList.concat(" " + classToAdd);
    }

    const Types = {
        'quote': styles.quote,
    }

    if (props.type) {
        addClass(Types[props.type])
    }

    return (
        <>
            <p className={classList}>{props.children}</p>
            { props.author && 
                <p className={styles.author}>– { props.author }</p>
            }
        </>
    )
}

Text.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    type: PropTypes.oneOf(['text', 'quote']),
}

export default Text

