import { Card, CardActions, CardContent, Typography } from '@material-ui/core'
import React from 'react'
import Container from '../../components/Container/Container'
import Content from '../../components/Content/Content'
import Icon from '../../components/Icon/Icon';
import IconText from "../../components/Icon/IconText";
import styles from './Kontakt.module.scss'

export default function Home() {
	return (
		<>
			<section style={{ backgroundImage: "url(/images/FlowBG_03.svg)", backgroundSize: "cover" }} >
				<Content fullscreen="true">

					<div className={styles.image_container}>
						<img src="images/team_cutted.png" alt="panel" className={styles.image} />
						<p className={styles.names}>
							Niklas Schulz • Oliver Schulz • Carsten Jansen
						</p>
					</div>

					<Container margin="true" size="small">
						<div className={styles.contact}>
							<h2>Kontakt</h2>
							<p>Senden sie uns eine E-Mail oder rufen sie uns direkt an. Wir stehen gerne für sie zur Verfügung.</p>
							<a href="tel:+49629857-86">
								<h4><div className={styles.icon}><Icon name="fas fa-phone-alt"  /></div> +49 (30) 629857-86</h4>
							</a>
							<a href="mailto:info@ichwillwerben.de">
								<h4><div className={styles.icon}><Icon name="far fa-envelope" /></div> info@ichwillwerben.de</h4>
							</a>
						</div>
					</Container>

				</Content>
		</section>
		</>
	)
}
