import React from 'react'
import Map from '../../components/Map/Map'

export default function Home() {
	return (
		<>
				<Map height={"100vh"} scrollWheelZoom={true} showMenu={true}/>
		</>
	)
}
