import { Card, } from '@material-ui/core'
import React from 'react'
import Container from '../../components/Container/Container'
import Center from '../../components/Center/Center'

export default function EmailSent(props) {
	return (
		<>
			<section style={{ backgroundImage: "url(/images/FlowBG_03.svg)", backgroundSize: "cover" }} >
				<Center height="100vh">
					<Container size='large' margin="true">
						<Card variant="outlined" style={{ padding: "calc(var(--margin-large))", maxWidth: '780px' }}>
							<h3>Email verschickt</h3>
							<p>Wir haben ihnen soeben eine E-Mail zugesandt, um ihre E-Mailadresse zu verifizieren.
							Bitte schauen sie in ihr Postfach und bestätigen die Anfrage.
								</p>
							<p>
								Bei Problemen oder Fragen kontaktieren sie uns gerne unter info@ichwillwerben.de
							</p>
						</Card>
					</Container>
				</Center>
			</section>
		</>
	)
}
