import { Card, CardContent, } from '@material-ui/core'
import React, { useState, } from 'react'
import Container from '../../components/Container/Container'
import Content from '../../components/Content/Content'
import { useLocation } from 'react-router'
import Button from '../../components/Button/Button'
import Line from '../../components/Line/Line'
import styles from './Buchen.module.scss'
import { useHistory } from "react-router"
import Flex from "../../components/Flex/Flex"
import FlexContainer from "../../components/Flex/FlexContainer"
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config.json"


export default function Buchen(props) {

	const { state } = useLocation();
	const checkedPanels = state?.checkedPanels ?? [];

	const history = useHistory();

	const [selectedStartDate, setSelectedStartDate] = useState(new Date());
	const [selectedEndDate, setSelectedEndDate] = useState(new Date());

	const [duration, setDuration] = useState(3)
	const [files, setFiles] = useState([])
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [email, setEmail] = useState("")
	const [company, setCompany] = useState("")
	const [address, setAddress] = useState("")
	const [reCAPTCHA, setReCAPTCHA] = useState("")
	const [tel, setTel] = useState("")


	let nowPlus = new Date()
	nowPlus.setMonth(nowPlus.getMonth() + 12);

	const price = checkedPanels.reduce((acc, panel) => acc + panel.price, 0);

	const handleStartDateChange = (date) => {
		setSelectedStartDate(new Date(date));
		if (selectedStartDate < selectedEndDate) {
			setSelectedEndDate(new Date(date));
		}
	}

	function FormatDate(date) {
		return date.toISOString().split("T")[0]
	}

	const handleChangePanels = () => {
		history.push('/karte')
	}

	const handleChangeDuration = (value) => {
		setDuration(parseInt(value))
	}
	const handleChangeFiles = (value) => {
		setFiles(value)
	}
	const handleChangeFirstName = (value) => {
		setFirstName(value)
	}
	const handleChangeLastName = (value) => {
		setLastName(value)
	}
	const handleChangeEmail = (value) => {
		setEmail(value)
	}
	const handleChangeCompany = (value) => {
		setCompany(value)
	}
	const handleChangeAddress = (value) => {
		setAddress(value)
	}
	const handleChangeReCAPTCHA = (value) => {
		setReCAPTCHA(value)
	}
	const handleChangeTel = (value) => {
		setTel(value)
	}

	const DisplayTypes = [
		{
			value: 3,
			label: '3 Monate',
		},
		{
			value: 6,
			label: '6 Monate',
		},
		{
			value: 12,
			label: '12 Monate',
		},
	];


	const canCheckout =
    checkedPanels.length > 0 &&
    firstName !== "" &&
    lastName !== "" &&
    email !== "" &&
    reCAPTCHA !== "";


	const handleSubmit = () => {

		const data = {
      checked: checkedPanels.map((panel) => panel._id),
      duration,
      date: FormatDate(selectedStartDate),
      files,
      firstName,
      lastName,
      email,
      tel,
      company,
      address,
      reCAPTCHA,
    };

		fetch('https://form.ichwillwerben.de/map', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
			.then(response => response.json())
			.then(data => {
				if (data.success) {
					history.push({
						pathname: '/email-received'
					})
				} else {
					alert("ein Fehler ist Aufgetreten!")
				}

			})
			.catch((e) => {
				console.error('Error:', e);
			});
	}


	return (
    <>
      <section style={{ backgroundColor: "var(--lighterGray" }}>
        <Content fullscreen={true}>
          <Container size="small" margin="true">
            <FlexContainer gap>
              <Flex size="1/2">
                <Card className={styles.card}>
                  <CardContent>
                    <h3>Displays</h3>
                    <Button fullWidth onClick={handleChangePanels}>
                      {checkedPanels.length} Display{checkedPanels.length > 1 ? "s" : ""} ausgewählt
                    </Button>
                    <Line />

                    <h3>Zeitraum</h3>
                    <label>Startdatum</label>
                    <input
                      type="date"
                      value={FormatDate(selectedStartDate)}
                      min={FormatDate(new Date())}
                      max={FormatDate(nowPlus)}
                      onChange={(e) => handleStartDateChange(e.target.value)}
                    />
                    <label>Dauer</label>
                    <select value={duration} onChange={(e) => handleChangeDuration(e.target.value)}>
                      {DisplayTypes.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <Line />
                    <ReCAPTCHA sitekey={config.reCAPTCHA} onChange={handleChangeReCAPTCHA} />

                    {/*
											<h3>Kampagne</h3>
											<input
												type="file"
												multiple
												onChange={(e) => handleChangeFiles(e.target.value)}
												accept=".jpeg,.jpg,.png,.gif,.mp3,.mp4"
											/>
										*/}
                  </CardContent>
                </Card>
              </Flex>
              <Flex size="1/2">
                <Card className={styles.card}>
                  <CardContent>
                    <h3>Daten</h3>
                    <label htmlFor="firstName">Vorname *</label>
                    <input
                      type="text"
                      name="firstName"
                      onChange={(e) => handleChangeFirstName(e.target.value)}
                    />
                    <label htmlFor="lastName">Nachname *</label>
                    <input
                      type="text"
                      name="lastName"
                      onChange={(e) => handleChangeLastName(e.target.value)}
                    />
                    <label htmlFor="email">E-Mail *</label>
                    <input
                      type="mail"
                      name="email"
                      onChange={(e) => handleChangeEmail(e.target.value)}
                    />
                    <label htmlFor="tel">Telefonnummer</label>
                    <input
                      type="tel"
                      name="tel"
                      onChange={(e) => handleChangeTel(e.target.value)}
                    />
                    <label htmlFor="company">Firmenname</label>
                    <input
                      type="text"
                      name="company"
                      onChange={(e) => handleChangeCompany(e.target.value)}
                    />
                    <label htmlFor="address">Adresse</label>
                    <input
                      type="text"
                      name="address"
                      onChange={(e) => handleChangeAddress(e.target.value)}
                    />
                  </CardContent>
                </Card>
              </Flex>
            </FlexContainer>

            <Card className={styles.price}>
              <div className={styles.submin_container}>
                <p>
                  <b>{price} €</b> pro Monat zzgl. Mwst.
                </p>
                <div></div>
                <p>
                  <b>{price * duration} €</b> Gesamt zzgl. Mwst.
                </p>
                <Button onClick={handleSubmit} disabled={!canCheckout}>
                  Angebot Senden
                </Button>
              </div>
            </Card>
          </Container>
        </Content>
      </section>
    </>
  );
}
