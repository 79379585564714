import React from 'react';
import Navbar from './components/Navbar/IwwNavbar'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import Footer from './components/Footer/Footer';

import Home from './routes/Home/Home'
import Impressum from './routes/Impressum/Impressum';
import Dsv from './routes/Dsv/Dsv';
import Karte from './routes/Karte/Karte'
import Kontakt from './routes/Kontakt/Kontakt'
import Buchen from './routes/Buchen/Buchen'
import EmailReceived from "./routes/EmailReceived/EmailReceived";
import EmailSent from "./routes/EmailSent/EmailSent";
import NotFound from "./routes/404/NotFound";

function App() {

  return (
    <>
      <Router>
        <Navbar/>
        <ScrollMemory />
        <Switch> {/* sorgt dafür dass nur eine rout genommen wird */}
          {/* Routes WITHOUT Standart UI */}
          <Route path="/karte" component={Karte} />
          <>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/impressum" component={Impressum} />
              <Route path="/dsv" component={Dsv} />
              <Route path="/kontakt" component={Kontakt} />
              <Route path="/buchen" component={Buchen} />
              <Route path="/email-received" component={EmailReceived} />
              <Route path="/email-sent" component={EmailSent} />
              {/* 404 route */}
              <Route component={NotFound} />
            </Switch>
            <Footer />
          </>
        </Switch>
      </Router>
    </>
  );
}

export default App;
