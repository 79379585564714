import { Card, } from '@material-ui/core'
import React from 'react'
import Container from '../../components/Container/Container'
import Center from '../../components/Center/Center'

export default function EmailSent(props) {
	return (
		<>
			<section style={{ backgroundImage: "url(/images/FlowBG_03.svg)", backgroundSize: "cover" }} >
				<Center height="100vh">
					<Container size='large' margin="true">
						<Card variant="outlined" style={{ padding: "calc(var(--margin-large))", maxWidth: '780px' }}>
							<h3>Fehler 404</h3>
							<p>
								Es tut uns leid wir konnten die von ihnen gesuchte Seite nicht finden.
								</p>
							<p>
								Falls dieser Fehler öfter auftritt, kontaktieren sie uns bitte unter info@ichwillwerben.de
							</p>
						</Card>
					</Container>
				</Center>
			</section>
		</>
	)
}
